@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 300;
	src: url('font/fonts/Gilroy/Gilroy-Light.ttf') format('truetype');
	font-display: swap; }


@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 400;
	src: url('font/fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
	font-display: swap; }

@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 500;
	src: url('font/fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
	font-display: swap; }

@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 600;
	src: url('font/fonts/Gilroy/Gilroy-SemiBold.ttf') format('truetype');
	font-display: swap; }

@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 700;
	src: url('font/fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
	font-display: swap; }
